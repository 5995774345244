import { ReactComponent as Telegram } from 'assets/telegram.svg'
import { ReactComponent as Twitter } from 'assets/twitter.svg'
import Animal from 'assets/landing/animal.png'
import AnimalMob from 'assets/landing/animalMob.png'
import Cell from 'assets/landing/cell.png'

const { Button } = require("components/button/button")

export const LandingTitle = () => {

  const isMobile = window.innerWidth <= 640

  return (
    <div className='pt-24 sm:pt-36 relative'>
      <div className='flex relative bg-darkPurple rounded-2xl py-5 px-5 sm:py-16 sm:px-8'>
        <div className='z-10'>
          <h3 className='text-3xl sm:text-6xl text-white  font-jetExtrabold w-full sm:w-3/5 mb-5 sm:mb-8'>
            <span className='text-blue'>First play-to-earn project</span> {isMobile && <br />} with real story on blockchain
          </h3>
          <p className='font-interRegular text-xl sm:text-3xl text-white mb-5 sm:mb-8'>GameFi project for everyone!</p>
          <div className='flex flex-col sm:flex-row gap-x-8 gap-y-5 sm:gap-y-0 sm:items-center mb-2'>
            <a className='w-fit py-3 px-5 bg-button text-white rounded-lg font-interSemibold'>Presentation</a>
            <div className='flex items-center gap-x-8'>
              <a href='https://twitter.com/gremlinsgem' target='_blank' className='flex items-center gap-8 cursor-pointer justify-center'>
                <Twitter className='w-10 h-10 mmx:w-8 mmx:h-8' />
              </a>
              <a href='https://t.me/gremlins_gem_channel' target='_blank' className='flex items-center gap-8 cursor-pointer justify-center'>
                <Telegram className='w-10 h-10 mmx:w-8 mmx:h-8' />
              </a>
            </div>
          </div>
        </div>
        {!isMobile && <img className='absolute left-0 top-0' src={Cell} />}
        <img className='right-0 bottom-0 absolute' src={isMobile ? AnimalMob : Animal} />
      </div>
      <div className='absolute -top-16 sm:-top-36 left-0 w-full h-2' id='home'></div>
    </div >
  )
}
