
export const Button = (props) => {

  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className={` ${props.className} 
        font-interMedium w-full transition shadow-custom bg-button
        whitespace-nowrap px-6 py-2 rounded-lg text-xl font-medium text-white
        overflow-hidden text-ellipsis button__wrapper
        `}>
      {props.children}
    </button>
  )
}
