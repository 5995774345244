import { useState } from "react"
import { ReactComponent as ArrowBack } from 'assets/arrow-back.svg'

const faqList = [
  {
    title: 'GREMLINS GEM: What is it and how does it work?',
    content: 'GREMLINS GEM is a play-to-earn game built on the BNB Chain blockchain. Players can earn in various ways within the game.'
  },
  {
    title: 'How to register in Gremlins Gem?',
    content: 'GREMLINS GEM is a play-to-earn game built on the BNB Chain blockchain. Players can earn in various ways within the game.'
  },
  {
    title: 'How to earn in Gremlins Gem?',
    content: 'GREMLINS GEM is a play-to-earn game built on the BNB Chain blockchain. Players can earn in various ways within the game.'
  },
  {
    title: 'Gremlins Gem Referral Program',
    content: 'GREMLINS GEM is a play-to-earn game built on the BNB Chain blockchain. Players can earn in various ways within the game.'
  },
  {
    title: 'What is the maximum and minimum number of GREMLIN tokens that can be purchased?',
    content: 'GREMLINS GEM is a play-to-earn game built on the BNB Chain blockchain. Players can earn in various ways within the game.'
  },
  {
    title: 'What is Booster and how does it work?',
    content: 'GREMLINS GEM is a play-to-earn game built on the BNB Chain blockchain. Players can earn in various ways within the game.'
  },
  {
    title: 'How many NFTs of each type can I have?',
    content: 'GREMLINS GEM is a play-to-earn game built on the BNB Chain blockchain. Players can earn in various ways within the game.'
  },
  {
    title: 'How to get Gremlin NFT?',
    content: 'GREMLINS GEM is a play-to-earn game built on the BNB Chain blockchain. Players can earn in various ways within the game.'
  },
  {
    title: 'How to withdraw profit from the GREMLINS GEM platform?',
    content: 'GREMLINS GEM is a play-to-earn game built on the BNB Chain blockchain. Players can earn in various ways within the game.'
  },
]

export const LandingFaq = () => {

  return (
    <div className='relative' >
      <h1 className='text-description text-3xl sm:text-6xl font-jetBold text-center mb-8 mt-12 sm:mb-14 sm:mt-20'>FAQ</h1>
      <div className='flex flex-col items-center gap-y-8'>
        {faqList.map(el => {
          return <FaqItem {...el} />
        })}
      </div>
      <div className='absolute -top-16 sm:-top-36 left-0 w-full h-2' id='faq'></div>
    </div>
  )
}

const FaqItem = (props) => {
  const [isOpened, setIsOpened] = useState(false)
  return (
    <div className='w-full bg-darkPurple rounded-2xl p-5 sm:p-8'>
      <div className='flex items-start justify-between'>
        <h3 className='text-description w-fit text-xl sm:text-3xl font-jetExtrabold'>{props.title}</h3>
        <div onClick={() => setIsOpened(!isOpened)} className='bg-button w-8 h-8 sm:w-10 sm:h-10 relative rounded-lg'>
          <ArrowBack className={`${!isOpened ? 'rotate-180' : 'rotate-0'} absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer`} />
        </div>
      </div>
      {
        isOpened && <p className='mt-5 text-description font-interMedium text-base sm:text-xl'>{props.content}</p>
      }
    </div>
  )
}
