import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import { Header, HeaderMenu } from 'components/header/header'; import { Footer } from 'components/footer/footer';
import { useState } from 'react';
import { LandingTitle } from 'components/LandingTitle/LandingTitle';
import { LandingAdvantages } from 'components/LandingAdvantages/LandingAdvantages';
import { LandingTokenomics } from 'components/LandingTokenomics/LandingTokenomics';
import { LandingFaq } from 'components/LandingFaq/LandingFaq';

/* global BigInt */
function App() {
  const [openedMenu, isOpenedMenu] = useState(false)


  return (
    <BrowserRouter>
      <Header openedMenu={openedMenu} setIsOpenedMenu={isOpenedMenu} />
      <div className=' wrapper mx-auto'>
        <LandingTitle />
        <LandingAdvantages />
        <LandingTokenomics />
        <LandingFaq />
      </div>
      <Footer />
      {openedMenu && <HeaderMenu setIsOpenedMenu={isOpenedMenu} />}
    </BrowserRouter>
  );
}

export default App;
