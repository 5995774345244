import { ReactComponent as Telegram } from 'assets/telegram.svg'
import { ReactComponent as Twitter } from 'assets/twitter.svg'
import Logo from 'assets/logo.png'
import { Link } from 'react-router-dom'

export const Footer = () => {

  return (
    <footer className='bg-darkPurple py-8 mt-10 sm:mt-20'>
      <div className='wrapper mx-auto flex flex-col-reverse gap-y-8 sm:flex-row items-center justify-between'>
        <Link to='/' className='flex items-center gap-x-3'>
          <img src={Logo} className='w-9 h-8 sm:w-12 sm:h-10 mmx:w-16 mmx:h-14 self-start ' />
          <p className='whitespace-nowrap font-jetBold text-xl sm:text-4xl mmx:text-5xl font-bold text-white uppercase'>gremlins gem</p>
        </Link>
        <div className='flex items-center gap-x-16'>
          <a href='https://twitter.com/gremlinsgem' target='_blank' className='flex items-center gap-8 cursor-pointer justify-center'>
            <Twitter className='w-10 h-10 mmx:w-12 mmx:h-12' />
          </a>
          <a href='https://t.me/gremlins_gem_channel' target='_blank' className='flex items-center gap-8 cursor-pointer justify-center'>
            <Telegram className='w-10 h-10 mmx:w-12 mmx:h-12' />
          </a>
        </div>
      </div>
    </footer >
  )
}
