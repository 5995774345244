import JabbaHutt from 'assets/landing/jabbaHutt.png'
import JabbaHuttMob from 'assets/landing/jabbaHuttMob.png'

export const LandingTokenomics = () => {

  const isMobile = window.innerWidth <= 640

  return (
    <div className='relative'>
      <h1 className='text-description text-3xl sm:text-6xl font-jetBold text-center mb-8 mt-12 sm:mb-14 sm:mt-20'>Tokenomics</h1>
      <div className='relative flex sm:justify-center sm:gap-y-52 sm:gap-x-80 flex-wrap'>
        <div className='relative mb-52 sm:mb-0 w-full sm:w-4/12 pt-8 sm:pt-12 h-min px-5 pb-5 tokenomics__block-left '>
          <h1 className='absolute top-0 left-1/2 text-3xl sm:text-5xl font-jetExtrabold text-lightPurple -translate-y-1/2  -translate-x-1/2 whitespace-nowrap'>Gremlin NFT</h1>
          <p className='text-base sm:text-xl text-white font-interMedium'>Non fungible token on <span className='text-yellow'>BNB chain</span>. It is BEP-721 token.There 6 types of this NFT which provides new <span className='text-blue'>GREMLIN</span> tokens everyday.</p>
        </div>
        <div className='relative mb-16 sm:mb-0 ml-auto w-full sm:w-4/12 pt-8 sm:pt-12 px-5 pb-5 tokenomics__block-left '>
          <h1 className='absolute top-0 left-1/2 text-3xl sm:text-5xl font-jetExtrabold text-lightPurple -translate-y-1/2  -translate-x-1/2 whitespace-nowrap'>H2O</h1>
          <p className='text-base sm:text-xl text-white font-interMedium'>Utility token made on <span className='text-yellow'>BNB chain</span>. It is BEP-20 token. 100% tokens will be distributed to users - team has no any share. <span className='text-blue'>H2O</span> was made as a technical token for helping project economy.</p>
        </div>
        <div className='relative w-full sm:w-8/12 pt-8 sm:pt-12 px-5 pb-5 tokenomics__block '>
          <h1 className='absolute top-0 left-1/2 text-3xl sm:text-5xl font-jetExtrabold text-lightPurple -translate-y-1/2  -translate-x-1/2 whitespace-nowrap'>Gremlin</h1>
          <p className='text-base sm:text-xl text-white font-interMedium'>Utility token made on <span className='text-yellow'>BNB chain</span>. It is BEP-20 token with unique functionality. Transfers of <span className='text-blue'>GREMLIN</span> token from one address to another address burns all <span className='text-blue'>H2O</span> tokens. This perfect functionality was made to protect users from abusers. 100% tokens will be distributed to users - team has no any share. GREMLIN tokens can be burned after minting <span className='text-blue'>Gremlin NFT</span>.</p>
        </div>
        <img className=' w-full sm:w-auto sm:h-auto absolute left-1/2 top-64 sm:top-1/2 -translate-x-1/2 -translate-y-1/2' src={isMobile ? JabbaHuttMob : JabbaHutt} />
      </div>
      <div className='absolute -top-12 sm:-top-36 left-0 w-full h-2' id='tokenomics'></div>
    </div>
  )
}
