import Adv1 from 'assets/landing/advantages/adv1.png'
import Adv12 from 'assets/landing/advantages/adv12.png'
import Adv2 from 'assets/landing/advantages/adv2.png'
import Adv22 from 'assets/landing/advantages/adv22.png'
import Adv3 from 'assets/landing/advantages/adv3.png'
import Adv32 from 'assets/landing/advantages/adv32.png'
import Adv4 from 'assets/landing/advantages/adv4.png'
import Adv42 from 'assets/landing/advantages/adv42.png'

import Slide1 from 'assets/landing/advCarousel/slide1.png'
import Slide2 from 'assets/landing/advCarousel/slide2.png'
import Slide3 from 'assets/landing/advCarousel/slide3.png'
import Slide4 from 'assets/landing/advCarousel/slide4.png'

import styles from 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

const mobileAdv = [
  { title: 'Buy and Hold', description: 'Get more tokens and earn on their price action', img: Slide1 },
  { title: 'Play and earn', description: 'In easy steps you can earn a lot of tokens and have fun', img: Slide2 },
  { title: 'Arbitrage', description: 'Check tokens price on different exchanges and earn on gaps', img: Slide3 },
  { title: 'Invite and earn', description: 'You can earn more than $100 000 by inviting your friends in this game', img: Slide4 },
]

export const LandingAdvantages = () => {

  const isMobile = window.innerWidth <= 640

  return (
    <div className='relative'>
      <h1 className='text-description text-3xl sm:text-6xl font-jetBold text-center mb-8 mt-12 sm:mb-14 sm:mt-20'>Advantages</h1>
      {
        isMobile
          ? <AdvantagesCarousel />
          : <Advantages />
      }
      <div className='absolute -top-16 sm:-top-36 left-0 w-full h-2' id='advantages'></div>
    </div>
  )
}

const AdvantagesCarousel = () => {
  return (
    <Carousel
      showArrows={false}
      showStatus={false}
      autoPlay={true}
    >
      {mobileAdv.map(el => <AdvantagesCarouselItem {...el} />)}
    </Carousel>
  )
}

const AdvantagesCarouselItem = (props) => {
  return (
    <div className='relative bg-advantagesBlock  flex-half px-5 py-8 rounded-2xl h-56'>
      <h3 className='font-jetExtrabold text-white text-3xl mb-5'>{props.title}</h3>
      <p className='text-xl font-interMedium text-white'>{props.description}</p>
      <img className='absolute h-full left-0 bottom-0' src={props.img} />
    </div>
  )
}

const Advantages = () => {
  return (
    <div className='flex flex-wrap flex-row  gap-8 '>

      <div className='relative bg-advantagesBlock  flex-half px-8 py-10 rounded-2xl'>
        <h3 className='relative z-10 font-jetExtrabold text-white text-4xl mb-8'>Buy and Hold</h3>
        <p className='text-3xl relative z-10 font-interMedium text-white'>Get more tokens and earn on their price action</p>
        <img className='absolute h-full left-0 bottom-0' src={Adv1} />
        <img className='absolute  h-full right-0 bottom-0' src={Adv12} />
      </div>

      <div className='relative bg-advantagesBlock  flex-half px-8 py-10 rounded-2xl'>
        <h3 className='relative z-10 font-jetExtrabold text-white text-4xl mb-8'>Play and earn</h3>
        <p className='relative z-10 text-3xl font-interMedium text-white'>In easy steps you can earn a lot of tokens and have fun</p>
        <img className='absolute h-full left-0 bottom-0' src={Adv2} />
        <img className='absolute  h-full right-0 bottom-0' src={Adv22} />
      </div>

      <div className='relative bg-advantagesBlock flex-half px-8 py-10 rounded-2xl'>
        <h3 className='relative z-10 font-jetExtrabold text-white text-4xl mb-8'>Arbitrage</h3>
        <p className='relative z-10 text-3xl font-interMedium text-white'>Check tokens price on different exchanges and earn on gaps</p>
        <img className='absolute h-full left-0 bottom-0' src={Adv3} />
        <img className='absolute  h-full right-0 bottom-0' src={Adv32} />
      </div>

      <div className='relative bg-advantagesBlock flex-half px-8 py-10 rounded-2xl'>
        <h3 className='relative z-10 font-jetExtrabold text-white text-4xl mb-8'>Invite and earn</h3>
        <p className='relative z-10 text-3xl font-interMedium text-white'>You can earn more than $100 000 by inviting your friends in this game</p>
        <img className='absolute h-full left-0 bottom-0' src={Adv4} />
        <img className='absolute  h-full right-0 bottom-0' src={Adv42} />
      </div>
    </div>
  )
}
