import Logo from 'assets/logo.png'
import { useCallback, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as Menu } from 'assets/menu.svg'
import { ReactComponent as Close } from 'assets/close.svg'

export const Header = (props) => {

  const isMobile = window.innerWidth <= 640

  return (
    <header className='fixed z-20 top-0 left-0 overflow-hidden bg-bgPurple w-full flex items-center py-5'>
      <div className='flex wrapper items-center justify-between sm:mx-auto '>
        <Link to='#home' className='flex items-end gap-x-3 w-fit'>
          <img src={Logo} className='w-9 h-8 sm:w-12 sm:h-10 mmx:w-16 mmx:h-14 self-start ' />
          <p className='whitespace-nowrap font-jetBold text-xl sm:text-4xl mmx:text-5xl font-bold text-white uppercase'>gremlins gem</p>
        </Link>
        {
          !isMobile && <div className='flex text-base  font-jetRegular tracking-widest justify-evenly text-white w-full'>
            <a href='#home' className={`cursor-pointer hover:text-lightPurple transition`} >Home</a>
            <a href='#advantages' className={`cursor-pointer hover:text-lightPurple transition`} >Advantages</a>
            <a href='#tokenomics' className={`cursor-pointer hover:text-lightPurple transition`} >Tokenomics</a>
            <a href='#faq' className={`cursor-pointer hover:text-lightPurple transition`} >FAQ</a>
          </div>
        }
        {
          !isMobile
            ? <div className='flex items-center gap-x-5'>
              <a
                href='https://gremlinsgem.com/'
                className='font-interMedium w-full transition shadow-custom bg-button
                  whitespace-nowrap px-6 py-2 rounded-lg text-xl font-medium text-white
                  overflow-hidden text-ellipsis button__wrapper'
                disabled={false}>Launch App</a>
            </div>
            : props.openedMenu
              ? <div onClick={() => props.setIsOpenedMenu(false)}>
                <Close />
              </div>
              : <div onClick={() => props.setIsOpenedMenu(true)}>
                <Menu />
              </div>
        }
      </div>
    </header>
  )
}

export const HeaderMenu = (props) => {

  return (
    <div className='fixed z-10 w-screen h-screen bg-bgPurple left-0 top-0 flex flex-col items-center'>
      <div className='flex flex-col font-jetMedium mt-36 mb-20 gap-y-24 items-center text-4xl font-medium justify-evenly text-white w-full'>
        <a onClick={() => props.setIsOpenedMenu(false)} href='#home' className={`cursor-pointer hover:text-lightPurple transition`} >Home</a>
        <a onClick={() => props.setIsOpenedMenu(false)} href='#advantages' className={`cursor-pointer hover:text-lightPurple transition`} >Advantages</a>
        <a onClick={() => props.setIsOpenedMenu(false)} href='#tokenomics' className={`cursor-pointer hover:text-lightPurple transition`} >Tokenomics</a>
        <a onClick={() => props.setIsOpenedMenu(false)} href='#faq' className={`cursor-pointer hover:text-lightPurple transition`} >FAQ</a>
      </div>
      <div className='flex items-center gap-x-5'>
        <a
          href='https://gremlinsgem.com/'
          className='font-interMedium w-full transition shadow-custom bg-button
                  whitespace-nowrap px-6 py-2 rounded-lg text-xl font-medium text-white
                  overflow-hidden text-ellipsis button__wrapper'
          disabled={false}>Launch App</a>
      </div>
    </div>
  )
}
